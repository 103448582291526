import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { isCommentExists } from '@lib/core/comments/filters';
import { useCommentsHandler } from '@lib/core/comments/hooks/useCommentsHandler';
import { selectAllFeedbacksByProductId } from '@lib/core/products/selectors/productFeedback';
import { TParsedProductInstance, TProductInstance } from '@lib/core/products/types';
import { useApp } from '@lib/core/service/hooks';
import { getVinhoodTagsFromCharacteristics } from '@lib/core/service/utils';
import { useProductList, useProductRate } from '@lib/core/users/hooks';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import {
  DIETARY_PREFERENCE_EXPOSURE,
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';
import { useAddons } from '@lib/tools/views/hooks';

import AlcoholIcon from '@components/web/src/assets/icons/largeProductPage/alcohol.svg';
import AromaIcon from '@components/web/src/assets/icons/largeProductPage/aroma.svg';
import FromIcon from '@components/web/src/assets/icons/largeProductPage/from.svg';
import GrapeIcon from '@components/web/src/assets/icons/largeProductPage/grape.svg';
import StyleBeerIcon from '@components/web/src/assets/icons/largeProductPage/styleBeer.svg';
import StyleCoffeeIcon from '@components/web/src/assets/icons/largeProductPage/styleCoffee.svg';
import TasteIcon from '@components/web/src/assets/icons/largeProductPage/taste.svg';

export interface IIdentityForKiosk {
  title: ILocaleText;
  description: string;
  icon: string;
  order: number;
}
interface IIdentity {
  title: ILocaleText;
  description: string;
}

export interface IIdentitiesForWeb {
  identityWarningText: ILocaleText;
  productIdentities: IIdentity[];
}

export const parseBasicProductData = (productInstanceData: TProductInstance) => {
  const {
    product,
    promotions,
    price,
    identifier,
    preferences,
    url,
    character,
    discount,
    taste_match: tasteMatch,
    ecommerce_id: productEcommerceId,
    description: productInstanceDescription,
    tags,
  } = productInstanceData || {};

  const {
    name,
    image,
    attributes,
    producer,
    characteristics,
    category,
    // product_category is only for the limited catalog and it will be removed
    product_category: pc,
    description = '',
    format,
    // character is only for the limited catalog and it will be removed
    character: productCharacter,
    region,
    regions,
  } = product || {};
  const productId = product?.identifier || '';
  const productInstanceId = identifier || '';

  const productCategory = category || pc || '';
  // this is needed until we don't remove the limited catalog, then we have to refactor it
  const productCharacterName = character?.name || productCharacter?.name || '';
  const productCharacterDescription = character?.character_description || character?.charDesc || '';
  //
  const productCharacterId = character?.identifier || product?.character?.identifier || '';
  const productCharacterTypeIdentifier = character?.type?.identifier;
  // this is needed until we don't remove the limited catalog, then we have to refactor it
  const productCharacterTechnicalCaption = character?.techCaption || productCharacter?.technical_caption || '';
  const productCharacterImage = character?.image || productCharacter.image || '';
  //

  const productProducerName = producer?.name || '';
  const productFormatName = format?.name || '';
  const productFormatIdentifier = format?.identifier || '';
  // this is needed until we don't remove the limited catalog, then we have to refactor it
  const productRegionName = (!!region?.length && region[0]?.name) || (!!regions?.length && regions[0]?.name) || '';
  const productRegionCountry =
    (!!region?.length && region[0]?.country) || (!!regions?.length && regions[0]?.country) || '';
  //
  const productProducerUrl = url || '';
  const productStyle = [PRODUCT_CATEGORY_BEER, PRODUCT_CATEGORY_COFFEE].includes(productCategory) ? description : '';
  const productTasteMatchValue = typeof tasteMatch === 'number' ? tasteMatch : null;

  const productLocationMap = tags?.image || '';
  const productCurrency = price?.currency || productInstanceData?.currency || '';
  const productCurrencySymbol = LocaleUtils.getCurrencySymbolFromCode(price?.currency || '');
  const productOriginalPrice = Number(price?.value) || Number(price) || 0;
  const productDiscountPrice = Number(discount?.price?.value) || Number(discount?.price) || 0;
  const showedProductOriginalPrice = productOriginalPrice ? `${productOriginalPrice} ${productCurrencySymbol}` : null;
  const showedProductDiscountPrice = productDiscountPrice ? `${productDiscountPrice} ${productCurrencySymbol}` : null;

  const productTags = getVinhoodTagsFromCharacteristics(characteristics || []);
  const productCharacteristicsNames = characteristics?.map(v => v.name) || [];
  const productBadges = preferences
    ? preferences.filter(item => item.exposure === DIETARY_PREFERENCE_EXPOSURE.TAGS).map(tag => tag.name)
    : [];
  const productPreferencesSlug = preferences
    ? preferences.filter(item => item.exposure === DIETARY_PREFERENCE_EXPOSURE.TAGS).map(tag => tag.slug)
    : [];

  return {
    attributes,
    character,
    producer,
    productBadges,
    productCategory,
    productCharacterDescription,
    productCharacterId,
    productCharacterImage,
    productCharacterName,
    productCharacterTechnicalCaption,
    productCharacterTypeIdentifier,
    productCharacteristicsNames,
    productCurrency,
    productCurrencySymbol,
    productDiscountPrice,
    productEcommerceId,
    productFormat: format || null,
    productFormatIdentifier,
    productFormatName,
    productId,
    productImage: image || '',
    productInstanceDescription,
    productInstanceId,
    productLocationMap,
    productName: name || '',
    productOriginalPrice,
    productPreferencesSlug,
    productProducerName,
    productProducerUrl,
    productRegionCountry,
    productRegionName,
    productStyle,
    productTags,
    productTasteMatchValue,
    promotions,
    region,
    regions,
    showedProductDiscountPrice,
    showedProductOriginalPrice,
  };
};

export const useParsedProducts = () => {
  const { locale } = useApp();
  // Comment Data
  const {
    isCommentUpdateLoading,
    commentsList,
    handleComment,
    isCommentsDataLoading,
    isNewCommentsDataFetching,
    setIsNewCommentsDataFetching,
  } = useCommentsHandler();
  const answeredProductsFeedbacksByProductsId = useSelector(selectAllFeedbacksByProductId);
  // Wishlist Data
  const { wishlistProductInstanceIds, isWishlistProductListLoading, handleUpdateWishlistProductList } =
    useProductList();
  // Feedback Data
  const {
    handleUpdateProductRating,
    productRateData,
    discoveryQuizProducts,
    productRateOptions,
    isProductRateDataLoading,
    lastUpdatedProductRatingId,
    isProductRateUpdateLoading,
  } = useProductRate();

  const { isEnableLocationMapAddon, isEnableVusionAddon, isShowProductLocationAddon, isInteractiveTastingAddon } =
    useAddons();

  const parseProduct = useCallback(
    (productInstanceData: TProductInstance) => {
      if (!productInstanceData) return null;

      const {
        producer,
        productBadges,
        productCategory,
        productCharacterId,
        productCharacterName,
        productCharacterImage,
        productCharacterTechnicalCaption,
        productCharacterDescription,
        productCharacterTypeIdentifier,
        productCharacteristicsNames,
        productCurrency,
        productCurrencySymbol,
        productDiscountPrice,
        productEcommerceId,
        productFormat,
        productFormatIdentifier,
        productFormatName,
        productId,
        productImage,
        productInstanceId,
        productName,
        productOriginalPrice,
        productPreferencesSlug,
        productProducerName,
        productProducerUrl,
        productRegionCountry,
        productRegionName,
        productStyle,
        productTags,
        productTasteMatchValue,
        promotions,
        region,
        regions,
        showedProductDiscountPrice,
        showedProductOriginalPrice,
        productLocationMap,
        productInstanceDescription,
        character,
        attributes,
      } = parseBasicProductData(productInstanceData);

      const productPerfectFor = (locale && character?.attributes?.[`when_to_drink_${locale}`]) || '';
      const productDescription = (locale && attributes?.[`story_${locale}`]) || '';
      const minCelsiusTemperature = character?.attributes?.ideal_temperature_min_celsius || 0;
      const maxCelsiusTemperature = character?.attributes?.ideal_temperature_max_celsius || 0;
      const alcohol = attributes?.alcohol || '';
      const productFormatSize = attributes?.quantity || '';
      const commentFromList = commentsList?.find(commentFromArray => isCommentExists(commentFromArray, productId));
      const isCommentInList = !!commentFromList;

      const handleSubmitComment = ({
        comment,
        isProducerFeedback,
      }: {
        comment: string;
        isProducerFeedback?: boolean;
      }) => handleComment({ comment, commentFromList, isCommentInList, isProducerFeedback, productId });

      const handleDiscoveryQuizProducts = () => discoveryQuizProducts(productInstanceData);

      const isProductInstanceInWishlist = wishlistProductInstanceIds?.includes(productId);
      const productRate = productRateData.find(
        singleFeedback => singleFeedback?.product?.identifier === productInstanceId,
      )?.rate;
      const isProductFeedbackFinished = answeredProductsFeedbacksByProductsId[productId]?.length === 3;

      const {
        productIdentitiesCard: {
          warningText: identityWarningText,
          notPresentText,
          typeTitleText,
          originTitleText,
          grapeTitleText,
          colorTitleText,
          alcoholTitleText,
          aromasTitleText,
          tasteTitleText,
          glassTitleText,
          temperatureTitleText,
          allergensTitleText,
          fermentationTitleText,
          specialIngredientTitleText,
          varietyTitleText,
          roastedTitleText,
          styleTitleText,
          fromTitleText,
        },
        productPage,
        commonMessages,
      } = localeCommon;

      const { publishedTerms } = LocaleUtils;
      const { vusionAddonDefaultDescription } = commonMessages;

      const vusionAddonDefaultDescriptionText = publishedTerms[vusionAddonDefaultDescription.id];

      const productLocationDescription =
        (isEnableVusionAddon && productEcommerceId
          ? productInstanceDescription || vusionAddonDefaultDescriptionText
          : productInstanceDescription) || '';

      const isFindProductFunctionalityEnable =
        isShowProductLocationAddon || isEnableLocationMapAddon || isEnableVusionAddon;

      const isFindProductButtonEnable =
        (isShowProductLocationAddon && !!productLocationDescription) ||
        (isEnableLocationMapAddon && productLocationMap) ||
        (isEnableVusionAddon && productEcommerceId);

      const notPresentTextStatic = publishedTerms[notPresentText.id];

      // KIOSK IDENTITIES DATA
      const identitiesForKiosk: IIdentityForKiosk[] = [
        {
          description: attributes?.[`aromas_${locale}`] || attributes?.[`aroma_${locale}`],
          icon: AromaIcon,
          order: 4,
          title: aromasTitleText,
        },
      ];

      if (productRegionName && productRegionCountry) {
        identitiesForKiosk.unshift({
          description: `${productRegionName}, ${productRegionCountry}`,
          icon: FromIcon,
          order: 1,
          title: fromTitleText,
        });
      }

      if (productCategory === PRODUCT_CATEGORY_WINE) {
        identitiesForKiosk.push(
          { description: attributes?.[`type_${locale}`], icon: GrapeIcon, order: 2, title: grapeTitleText },
          { description: alcohol ? `${alcohol}%` : '', icon: AlcoholIcon, order: 3, title: alcoholTitleText },
        );
      }

      if (productCategory === PRODUCT_CATEGORY_BEER) {
        identitiesForKiosk.push(
          {
            description: attributes?.[`style_${locale}`] || productDescription,
            icon: StyleBeerIcon,
            order: 2,
            title: styleTitleText,
          },
          { description: alcohol ? `${alcohol}%` : '', icon: AlcoholIcon, order: 3, title: alcoholTitleText },
        );
      }

      if (productCategory === PRODUCT_CATEGORY_COFFEE) {
        identitiesForKiosk.push(
          {
            description: attributes?.[`style_${locale}`] || productDescription,
            icon: StyleCoffeeIcon,
            order: 2,
            title: styleTitleText,
          },
          { description: attributes?.[`taste_${locale}`], icon: TasteIcon, order: 3, title: tasteTitleText },
        );
      }

      identitiesForKiosk.sort((a, b) => a.order - b.order);

      // WEB IDENTITIES DATA
      const identitiesForWeb: IIdentitiesForWeb = {
        identityWarningText,
        productIdentities: [
          { description: attributes?.[`type_${locale}`], title: typeTitleText },
          { description: `${productRegionName}, ${productRegionCountry}`, title: originTitleText },
          { description: attributes?.[`color_${locale}`], title: colorTitleText },
          {
            description: attributes?.[`aromas_${locale}`] || attributes?.[`aroma_${locale}`],
            title: aromasTitleText,
          },
          { description: attributes?.[`taste_${locale}`], title: tasteTitleText },
        ],
      };

      if (productCategory === PRODUCT_CATEGORY_WINE) {
        identitiesForWeb.productIdentities.push(
          { description: attributes?.[`variety_${locale}`], title: grapeTitleText },
          { description: alcohol ? `${alcohol}%` : '', title: alcoholTitleText },
          { description: character?.attributes?.[`ideal_glass_${locale}`], title: glassTitleText },
          {
            description: `${minCelsiusTemperature}° - ${maxCelsiusTemperature}°`,
            title: temperatureTitleText,
          },
          {
            description: attributes?.[`allergens_${locale}`] || notPresentTextStatic,
            title: allergensTitleText,
          },
        );
      }

      if (productCategory === PRODUCT_CATEGORY_BEER) {
        identitiesForWeb.productIdentities.push(
          { description: attributes?.[`variety_${locale}`], title: fermentationTitleText },
          { description: alcohol ? `${alcohol}%` : '', title: alcoholTitleText },
          {
            description: character?.attributes?.[`special_ingredient_${locale}`],
            title: specialIngredientTitleText,
          },
          { description: character?.attributes?.[`ideal_glass_${locale}`], title: glassTitleText },
          {
            description: `${minCelsiusTemperature}° - ${maxCelsiusTemperature}°`,
            title: temperatureTitleText,
          },
          {
            description: attributes?.[`allergens_${locale}`] || notPresentTextStatic,
            title: allergensTitleText,
          },
        );
      }

      if (productCategory === PRODUCT_CATEGORY_COFFEE) {
        identitiesForWeb.productIdentities.push(
          { description: attributes?.[`variety_${locale}`], title: varietyTitleText },
          { description: attributes?.[`transformation_location_${locale}`], title: roastedTitleText },
          {
            description: attributes?.[`allergens_${locale}`] || notPresentTextStatic,
            title: allergensTitleText,
          },
        );
      }

      const preparationsClassicText = productPage.preparationsClassic;
      const preparationsSpecialText = productPage.preparationsSpecial;

      const preparationsData =
        productCategory === PRODUCT_CATEGORY_COFFEE
          ? [
              {
                description: character?.preparationClassic?.technicalCaption,
                image: character?.preparationClassic?.metadata?.thumbnail,
                subtitle: preparationsClassicText,
                title: character?.preparationClassic?.characterName,
              },
              {
                description: character?.preparationSpecialty?.technicalCaption,
                image: character?.preparationSpecialty?.metadata?.thumbnail,
                subtitle: preparationsSpecialText,
                title: character?.preparationSpecialty?.characterName,
              },
            ]
          : [];

      const fullProductData: TParsedProductInstance = {
        // Remove TParsedProductInstance type
        attributes,
        commentFromList,
        handleDiscoveryQuizProducts,
        handleSubmitComment,
        handleUpdateProductRating,
        handleUpdateWishlistProductList,
        identitiesForKiosk,
        identitiesForWeb,
        isCommentInList,
        isCommentUpdateLoading,
        isCommentsDataLoading,
        isEnableLocationMapAddon,
        isEnableVusionAddon,
        isFindProductButtonEnable,
        isFindProductFunctionalityEnable,
        isNewCommentsDataFetching,
        isProductInstanceInWishlist,
        isProductRateDataLoading,
        isProductRateUpdateLoading,
        isWishlistProductListLoading,
        lastUpdatedProductRatingId,
        preparationsData,
        producer,
        productBadges,
        productCategory,
        productCharacterDescription,
        productCharacterId,
        productCharacterImage,
        productCharacterName,
        productCharacterTechnicalCaption,
        productCharacterTypeIdentifier,
        productCharacteristicsNames,
        productCurrency,
        productCurrencySymbol,
        productDescription,
        productDiscountPrice,
        productEcommerceId,
        productFeedbackStatus: {
          isDisabled: !isInteractiveTastingAddon,
          isEnabled: isInteractiveTastingAddon && !isProductFeedbackFinished,
          isFinished: isInteractiveTastingAddon && isProductFeedbackFinished,
        },
        productFormat,
        productFormatIdentifier,
        productFormatName,
        productFormatSize,
        productId,
        productImage,
        productInstanceData,
        productInstanceId,
        productLocationDescription,
        productLocationMap,
        productName,
        productOriginalPrice,
        productPerfectFor,
        productPreferencesSlug,
        productProducerName,
        productProducerUrl,
        productRate,
        productRateOptions,
        productRegionCountry,
        productRegionName,
        productStyle,
        productTags,
        productTasteMatchValue,
        promotions,
        region,
        regions,
        setIsNewCommentsDataFetching,
        showedProductDiscountPrice,
        showedProductOriginalPrice,
      };

      return fullProductData;
    },
    [
      isEnableLocationMapAddon,
      isCommentsDataLoading,
      isNewCommentsDataFetching,
      commentsList,
      locale,
      wishlistProductInstanceIds,
      isWishlistProductListLoading,
      isEnableVusionAddon,
      isShowProductLocationAddon,
      productRateData,
      isProductRateDataLoading,
      lastUpdatedProductRatingId,
      isInteractiveTastingAddon,
      answeredProductsFeedbacksByProductsId,
      isProductRateUpdateLoading,
      productRateOptions,
      isCommentUpdateLoading,
    ],
  );
  const parseProductsArray = (products: TProductInstance[]) => {
    if (!products) return null;

    const parsedProducts = products.map(productInstanceData => parseProduct(productInstanceData));

    return parsedProducts;
  };
  return { parseProduct, parseProductsArray };
};
