import { useEffect, useMemo } from 'react';

import { useProductFeedback } from '@lib/core/products/hooks/useProductFeedback';
import { ProductFeedbackSteps } from '@lib/core/products/slices/productFeedback';
import { useRetailerLocation } from '@lib/core/retailers/hooks';
import { useApp } from '@lib/core/service/hooks';

import { ModalWrapperNew } from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapperNew';
import { ProductFeedbackPageDone } from '@components/web/src/templates/ProductFeedback/ProductFeedbackPageDone/ProductFeedbackPageDone';
import { ProductFeedbackPageStart } from '@components/web/src/templates/ProductFeedback/ProductFeedbackPageStart/ProductFeedbackPageStart';
import { ProductFeedbackQuestionPage } from '@components/web/src/templates/ProductFeedback/ProductFeedbackQuestionPage/ProductFeedbackQuestionPage';
import { ProductFeedbackResultPage } from '@components/web/src/templates/ProductFeedback/ProductFeedbackResultPage/ProductFeedbackResultPage';
import { ProductFeedbackTutorial } from '@components/web/src/templates/ProductFeedback/ProductFeedbackTutorial/ProductFeedbackTutorial';

export const ProductFeedbackModalContainer = () => {
  const {
    toggleOpenedFeedbackModal,
    productFeedbackQuestionsData,
    productFeedbackStep,
    productFeedbackCurrentQuestion,
    forwardProductFeedbackStep,
    submitProductFeedbackAnswer,
    handleProductFeedbackAnswerDone,
    parsedProductInstanceData,
    isProductFeedbackDataOrOptionsLoading,
    isProductFeedbackAnswerSubmitLoading,
    productFeedbackBanners,
    getProductFeedbackQuestions,
    getProductFeedbackBanners,
    productFeedbackDataLocale,
    productFeedbackTutorialProduct,
    toggleProductFeedbackTutorial,
    finishedProductFeedbacksByProductId,
    getAllSubmittedProductFeedbacks,
  } = useProductFeedback();

  const handleGoBack = () => toggleOpenedFeedbackModal({});

  const { locale } = useApp();
  const { retailerLocationName } = useRetailerLocation();

  const StartComponent = useMemo(
    () => (
      <ProductFeedbackPageStart
        handleGoBack={handleGoBack}
        handleNavigate={forwardProductFeedbackStep}
        isLoading={isProductFeedbackDataOrOptionsLoading}
        parsedProductInstanceData={parsedProductInstanceData}
      />
    ),
    [],
  );

  const finishedFeedbacksForProduct = finishedProductFeedbacksByProductId[parsedProductInstanceData?.productId];

  useEffect(() => {
    if (
      !parsedProductInstanceData?.productFeedbackStatus?.isFinished &&
      (!productFeedbackQuestionsData?.choices || productFeedbackDataLocale !== locale)
    ) {
      getProductFeedbackQuestions({ locale });
    }

    if (parsedProductInstanceData?.productFeedbackStatus?.isFinished && productFeedbackDataLocale !== locale) {
      getAllSubmittedProductFeedbacks({ locale });
    }

    if (!productFeedbackBanners?.length) {
      getProductFeedbackBanners();
    }
  }, []);

  let Component;

  switch (productFeedbackStep) {
    case ProductFeedbackSteps.Start:
      Component = StartComponent; // Memoize because re-renders of FeedbackModalContainer.tsx resets a timer
      break;
    case ProductFeedbackSteps.Questions:
    case ProductFeedbackSteps.Comment:
    case ProductFeedbackSteps.Rating:
      if (productFeedbackQuestionsData) {
        Component = (
          <ProductFeedbackQuestionPage
            banners={productFeedbackBanners}
            handleClose={toggleOpenedFeedbackModal}
            handleProductFeedbackAnswerDone={handleProductFeedbackAnswerDone}
            handleSkip={forwardProductFeedbackStep}
            handleSubmitAnswer={submitProductFeedbackAnswer}
            isProductFeedbackAnswerSubmitLoading={isProductFeedbackAnswerSubmitLoading}
            parsedProductInstanceData={parsedProductInstanceData}
            productFeedbackCurrentQuestion={productFeedbackCurrentQuestion}
            productFeedbackStep={productFeedbackStep}
            questionsAmount={productFeedbackQuestionsData.choices?.questions?.length}
            subtitle={retailerLocationName}
          />
        );
      }
      break;
    case ProductFeedbackSteps.Done:
      Component = (
        <ProductFeedbackPageDone
          handleNavigate={toggleOpenedFeedbackModal}
          locale={locale}
          parsedProductInstanceData={parsedProductInstanceData}
        />
      );
      break;
    case ProductFeedbackSteps.TrainingDone:
      Component = (
        <ProductFeedbackResultPage
          answers={finishedFeedbacksForProduct}
          handleGoBack={toggleOpenedFeedbackModal}
          parsedProductInstanceData={parsedProductInstanceData}
        />
      );
      break;
    case ProductFeedbackSteps.Tutorial:
    default:
      Component = (
        <ProductFeedbackTutorial
          handleClose={() => toggleProductFeedbackTutorial({})}
          parsedProductInstanceData={productFeedbackTutorialProduct}
        />
      );
      break;
  }

  return (
    <ModalWrapperNew isOpen isTransparent={productFeedbackStep === ProductFeedbackSteps.Tutorial}>
      {Component}
    </ModalWrapperNew>
  );
};
