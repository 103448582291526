import { useDispatch, useSelector } from 'react-redux';

import { TProductInstance } from '@lib/core/products/types';
import { selectIsThirdPartyConnectModalShown } from '@lib/core/service/selectors';
import { setThirdPartyConnectModalOpenScenario } from '@lib/core/service/slices';
import { ThirdPartyConnectModalScenario } from '@lib/core/service/types/interface';
import {
  selectIsProductRateDataLoading,
  selectIsProductRateUpdateLoading,
  selectLastUpdatedProductProductRateId,
  selectProductRateData,
  selectProductRateOptions,
} from '@lib/core/users/selectors/productRate';
import { TProductRateValue, actionUpdateProductRate } from '@lib/core/users/slices/productRate';
import { fetchDiscoveryQuizProducts, setDiscoveryQuizProductInstance } from '@lib/tools/discoveryQuiz/slices';
import { useModals } from '@lib/tools/modals/hooks';
import { MODALS } from '@lib/tools/shared/helpers/consts';

export interface IHandleUpdateProductRatingProps {
  productRate: TProductRateValue;
  slug: string;
  productId: string;
  productName: string;
}

export const useProductRate = () => {
  const dispatch = useDispatch();
  const { openModal } = useModals();

  const productRateData = useSelector(selectProductRateData);
  const productRateOptions = useSelector(selectProductRateOptions);
  const isProductRateDataLoading = useSelector(selectIsProductRateDataLoading);
  const isProductRateUpdateLoading = useSelector(selectIsProductRateUpdateLoading);
  const lastUpdatedProductRatingId = useSelector(selectLastUpdatedProductProductRateId);
  const isThirdPartyConnectModalShown = useSelector(selectIsThirdPartyConnectModalShown);

  const handleUpdateProductRating = async ({
    productRate,
    slug,
    productId,
    productName,
  }: IHandleUpdateProductRatingProps) => {
    const shouldShowThirdPartyConnectModal =
      !(isThirdPartyConnectModalShown.rate5 || isThirdPartyConnectModalShown.rate4) &&
      (productRate === 40 || productRate === 50);

    if (shouldShowThirdPartyConnectModal) {
      dispatch(
        setThirdPartyConnectModalOpenScenario({
          productName,
          scenario: productRate === 40 ? ThirdPartyConnectModalScenario.RATE4 : ThirdPartyConnectModalScenario.RATE5,
        }),
      );
      openModal(MODALS.THIRD_PARTY_CONNECT_MODAL);
    }

    return dispatch(actionUpdateProductRate({ productId, productRate: slug }));
  };

  const discoveryQuizProducts = (productInstanceData: TProductInstance) => {
    dispatch(fetchDiscoveryQuizProducts(productInstanceData));
    dispatch(setDiscoveryQuizProductInstance(productInstanceData));
  };

  return {
    discoveryQuizProducts,
    handleUpdateProductRating,
    isProductRateDataLoading,
    isProductRateUpdateLoading,
    lastUpdatedProductRatingId,
    productRateData,
    productRateOptions,
  };
};
