import styled from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';

export const ProductFeedbackStartCardContainer = styled(Flexbox)`
  margin-top: 70px;
  position: relative;
  width: 343px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-end;
  background-color: ${STATIC_COLORS.base.white};
  padding: 0 16px 32px;
  align-items: center;
`;

export const ProductImageWrapper = styled(Flexbox)`
  position: relative;
  justify-content: center;
  height: 254px;
  width: 100%;
`;
export const ProductImage = styled(Image)`
  position: absolute;
  height: 310px;
  width: unset;
  object-fit: unset;
  bottom: 0;
`;
