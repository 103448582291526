import { FC } from 'react';

import ProductBackgroundIcon from '@components/web/src/assets/icons/training/product-background-icon.svg';
import * as S from '@components/web/src/organisms/ProductFeedback/ProductFeedbackDoneProductSection/styles';

export const TRAINING_DONE_PRODUCT_SECTION_VARIANT = {
  LARGE: 'lg',
  SMALL: 'sm',
} as const;

export type TTrainingDoneProductSectionVariant =
  (typeof TRAINING_DONE_PRODUCT_SECTION_VARIANT)[keyof typeof TRAINING_DONE_PRODUCT_SECTION_VARIANT];

interface IProps {
  image: string;
  variant: TTrainingDoneProductSectionVariant;
}

export const ProductFeedbackDoneProductSection: FC<IProps> = ({
  image = '',
  variant = TRAINING_DONE_PRODUCT_SECTION_VARIANT.SMALL,
}) => (
  <S.ProductFeedbackDoneCardContainer $variant={variant} align="center" justify="center">
    <S.Background $variant={variant} alt="background-image" src={ProductBackgroundIcon} />
    <S.ProductImage $variant={variant} alt="Product Image" objectFit="contain" src={image} />
  </S.ProductFeedbackDoneCardContainer>
);
