import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getHeaderAppLogoUrl } from '@app/native/src/helpers/headerApp';
/* eslint-disable import/no-extraneous-dependencies */
import ThirdPartyConnectModalContainer from '@app/web/src/containers/ThirdPartyConnectModalContainer';

import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { GPRL_CHARACTER_QUERY, GPRL_PROMOTIONS_QUERY, isApplicationKiosk } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useKioskRoot } from '@lib/core/service/hooks/useKioskRoot';
import { useScrollToTop } from '@lib/core/service/hooks/useScrollToTop';
import { useServiceInstance } from '@lib/core/service/hooks/useServiceInstance';
import { useThirdParty } from '@lib/core/service/hooks/useThirdParty';
import { setIsTasteIdGuideShown, setServiceLocale, setShouldHideDownloadAppCard } from '@lib/core/service/slices';
import { isAppInIframe, prependBasename } from '@lib/core/service/utils';
import { useProductList, useProductRate, useUser } from '@lib/core/users/hooks';
import { useFidelityCard } from '@lib/core/users/hooks/useFidelityCard';
import { useUserKiosk } from '@lib/core/users/hooks/useUserKiosk';
import { USER_ROLE_ANONYMOUS } from '@lib/core/users/utils/consts';
import UserUtils from '@lib/core/users/utils/users';
import { isFromSharedLink } from '@lib/tools/comms/utils';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { useExploreLocations } from '@lib/tools/explore/hooks';
import { useModals } from '@lib/tools/modals/hooks';
import { fetchPromotionProducts, resetPromotionProducts } from '@lib/tools/promotionProducts/slices';
import RouteUtils from '@lib/tools/routes';
import { MODALS, PRODUCT_CATEGORY_QUERY, PROMOTION_LABEL_SLUG } from '@lib/tools/shared/helpers/consts';
import { useToastMessage } from '@lib/tools/toastMessage/hooks';
import { useAddons, useRetailerDesignSet } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';
import Portal from '@lib/tools/views/web/components/Portal';

import { HeaderAppProps } from '@components/web/src/app/HeaderApp/HeaderApp';
import { IFooterAppLoggedProps } from '@components/web/src/atoms/Footers/FooterAppLogged/FooterAppLogged';
import { HeaderFooterApp, HeaderFooterKiosk, HeaderFooterWidget } from '@components/web/src/atoms/HeaderFooter';
import RootElement from '@components/web/src/atoms/RootElement/rootElement';
import SplashScreenContainer from '@components/web/src/templates/SplashScreen/SplashScreenContainer';

type ISendHocProps = {
  footerProps: IFooterAppLoggedProps & { showGearIcon?: boolean; shouldHideFooter?: boolean };
  headerProps: HeaderAppProps;
  isAuthenticated: boolean;
  isAuthFooter?: boolean;
};

// ? Separate view (header/footer) logic and api-store logic
const HocWrapper = ({
  children,
  isHeaderShown = true,
  showSearch = true,
  showNotification = false,
  showGearIcon = true,
}) => {
  useScrollToTop();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { retailerLanguages, isAnonymousRetailerAccess, retailerName } = useRetailer();
  const { retailerLocationProducerId, retailerLocationName } = useRetailerLocation();
  const { handleResetKioskUserSession } = useKioskRoot();

  const { openModal } = useModals();
  const { isDisableHomeAddon, isExternalCharacterPage, isEnableAppBanner } = useAddons();

  const {
    userImage,
    userRole,
    isUserAuthenticated,
    userBestMatchCharacterForCurrentServiceProductCategory,
    isUserHasCharacterForCurrentPC,
    isUserHasAnyCharacter,
  } = useUser();

  const { isUserKioskAdmin } = useUserKiosk();
  const { isShowThirdPartyAcceptance } = useThirdParty();
  const { toastMessages, handleClearToastMessage } = useToastMessage();

  const { isDesignSetVinhoodApp, isDesignSetVinhoodExperience } = useRetailerDesignSet();
  const {
    productCategory,
    isSplashScreenPageShown,
    locale,
    isTasteIdGuideShown,
    shouldHideDownloadAppCard,
    isDevToolsEnabled,
  } = useApp();
  const { fidelityCardId } = useFidelityCard();
  const { isServiceInstanceFeatureEnabled } = useServiceInstance();
  const currentUserCharacterId = userBestMatchCharacterForCurrentServiceProductCategory?.characterId;

  const isHomePage = RouteUtils.getPage().includes(PAGES.vinhood.home);
  const isWelcomePage = RouteUtils.getPage().includes(PAGES.vinhood.welcome);
  const isTestResultPage = RouteUtils.getPage().includes(PAGES.vinhood.quizResults.result);
  const isRedirectPage = RouteUtils.getPage().includes(PAGES.vinhood.redirect);
  const isCatalogPage = RouteUtils.getPage().includes(PAGES.vinhood.catalog);
  const isThirdPartyPage = RouteUtils.getPage().includes(PAGES.vinhood.thirdParty);
  const isRegistrationPage = RouteUtils.getPage().includes(PAGES.vinhood.registration);
  const isJournalPage = [PAGES.vinhood.tasteId.tasteIdProductsJournal, PAGES.vinhood.tasteId.tasteIdPlacesJournal].some(
    page => RouteUtils.getPage().includes(page),
  );

  const { productRateData, isProductRateDataLoading } = useProductRate();
  const producerName = retailerLocationName;
  const { exploreLocationList } = useExploreLocations();
  const { wishlistProductInstanceIds, isWishlistProductListLoading } = useProductList();
  const shouldHideCatalogLink = !isUserHasAnyCharacter || isExternalCharacterPage;

  useEffect(() => {
    /** If Service Instance (SI) feature is enabled:
     * Anon user:
     * - with character for SI PC navigate to App Registration page
     * - without character for SI PC navigate to App Start page
     * Auth user:
     * - with character for SI PC navigate to Widget Policy Acceptance page
     * - without character for SI PC navigate to Widget Home page
     */

    if (!isServiceInstanceFeatureEnabled) return;

    if (!isUserAuthenticated && currentUserCharacterId) {
      navigate(prependBasename(PAGES.vinhood.registration));
    } else if (isUserAuthenticated) {
      RouteUtils.navigateToWidgetServiceInstance(currentUserCharacterId ? PAGES.vinhood.catalog : PAGES.vinhood.home);
    }
  }, [isServiceInstanceFeatureEnabled]);

  useEffect(() => {
    if (isApplicationKiosk && fidelityCardId) {
      /** For kiosk users with fidelity cards:
       * Changing loyalty card updates data
       * Changing user character checks availability of promotional products
       * Feature only works if promotional products are available for user's current character */
      dispatch(resetPromotionProducts());

      if (currentUserCharacterId) {
        dispatch(
          fetchPromotionProducts({
            [GPRL_CHARACTER_QUERY]: currentUserCharacterId,
            [GPRL_PROMOTIONS_QUERY]: PROMOTION_LABEL_SLUG,
            [PRODUCT_CATEGORY_QUERY]: productCategory,
            offset: 0,
          }),
        );
      }
    }
  }, [fidelityCardId, currentUserCharacterId]);

  const shouldShowTasteIdGuide =
    isUserAuthenticated &&
    !isTasteIdGuideShown &&
    [...productRateData, ...wishlistProductInstanceIds].length > 0 &&
    (!isWishlistProductListLoading || !isProductRateDataLoading);

  const handleSetTasteIdPageShown = () =>
    [...productRateData, ...wishlistProductInstanceIds].length > 0 &&
    (!isWishlistProductListLoading || !isProductRateDataLoading) &&
    dispatch(setIsTasteIdGuideShown(true));

  useEffect(() => {
    let timer;
    if (shouldShowTasteIdGuide) {
      timer = setTimeout(handleSetTasteIdPageShown, 10000);
    }
    return () => clearTimeout(timer);
  }, [shouldShowTasteIdGuide]);

  if (isDesignSetVinhoodApp) {
    const onHeaderIconClick = () => navigate(getHeaderAppLogoUrl());

    const showLanguageSwitcher = !isTestResultPage && userRole === USER_ROLE_ANONYMOUS;

    const sendHocProps: ISendHocProps = {
      footerProps: {
        isFooterDisabled: isDesignSetVinhoodApp ? !isUserHasAnyCharacter : !isUserHasCharacterForCurrentPC,
        profileImageUrl: userImage,
        shouldHideFooter: (isDesignSetVinhoodApp && isTestResultPage && !isUserAuthenticated) || isJournalPage,
        showGearIcon,
      },
      headerProps: {
        // check role and refactor again later if needed
        isHeaderShown: isHeaderShown && !isJournalPage,
        is_notification_enabled: !!userRole && showNotification,
        is_search_enabled: !!userRole && showSearch,
        is_show_language: showLanguageSwitcher,
        locale,
        onHeaderIconClick,
        retailerLanguages,
      },
      isAuthFooter: isUserAuthenticated && !isJournalPage,
      isAuthenticated: !!userRole,
    };

    return (
      <RootElement>
        <HeaderFooterApp {...sendHocProps}>{children}</HeaderFooterApp>
      </RootElement>
    );
  }

  if (isDesignSetVinhoodExperience) {
    const shouldHideHeader = (!isUserAuthenticated && isRegistrationPage) || isThirdPartyPage;
    const shouldHideAnonymousFooter = isUserAuthenticated || isThirdPartyPage || isFromSharedLink;
    const shouldShowAnonymousFooterWave = isHomePage && !isUserAuthenticated;
    const shouldHideLoggedFooter =
      !isUserAuthenticated || isAnonymousRetailerAccess || isThirdPartyPage || isFromSharedLink;
    const shouldShowDownloadAppCard = !shouldHideDownloadAppCard && isEnableAppBanner && isCatalogPage;
    const isToShowThirdPartyModal = isUserAuthenticated && isShowThirdPartyAcceptance;
    const isEnableLanguageSwitcher = !isUserAuthenticated && retailerLanguages.length > 1;
    const isMultipleProducer = exploreLocationList.length > 1;

    const handleLogoClick = () => {
      const shouldNavigateUserToTest = isDisableHomeAddon && isUserAuthenticated && isCatalogPage;

      const url = shouldNavigateUserToTest ? PAGES.vinhood.quiz.chooseExpert : PAGES.vinhood.home;
      return !isFromSharedLink ? navigate(prependBasename(url)) : null;
    };

    const handleSettingPageNavigation = () => navigate(prependBasename(PAGES.vinhood.tasteId.tasteIdSettings));

    const handleHideDownloadAppClick = () => dispatch(setShouldHideDownloadAppCard());

    const handleDownloadAppClick = () => {
      MixpanelTracker.events.bannerClick(
        'NL0057',
        'Download app',
        {
          identifier: 'R00019',
          route: 'hint',
        },
        {
          identifier: 'R00014',
          route: 'catalog',
        },
      );
      openModal(MODALS.DOWNLOAD_APP_MODAL);
    };

    const handleExitNavigation = () => {
      let page = '';
      if (!UserUtils.isUserHasCharactersForEachRetailerLocationCategories()) {
        page = PAGES.vinhood.start;
      } else if (UserUtils.isUserHasCharactersForEachRetailerLocationCategories() && !isUserAuthenticated) {
        page = PAGES.vinhood.registration;
      } else {
        page = PAGES.vinhood.home;
      }

      RouteUtils.navigateToAppServiceInstance(page);
    };

    const handleSelectLanguage = (selectedLanguageCode: string) => dispatch(setServiceLocale(selectedLanguageCode));

    return isRedirectPage ? (
      <>{children}</>
    ) : (
      <RootElement>
        {!isSplashScreenPageShown && !isDevToolsEnabled ? (
          <Portal>
            <SplashScreenContainer />
          </Portal>
        ) : (
          <HeaderFooterWidget
            handleClearToastMessage={handleClearToastMessage}
            handleDownloadAppClick={handleDownloadAppClick}
            handleExitNavigation={handleExitNavigation}
            handleHideDownloadAppClick={handleHideDownloadAppClick}
            handleLogoClick={handleLogoClick}
            handleSelectLanguage={handleSelectLanguage}
            handleSetTasteIdPageShown={handleSetTasteIdPageShown}
            handleSettingPageNavigation={handleSettingPageNavigation}
            isEnableExitNavigation={false}
            isEnableLanguageSwitcher={isEnableLanguageSwitcher}
            isEnableSettingsNavigation={isUserAuthenticated}
            isFixedBody={isJournalPage}
            isHideGearIcon={isAppInIframe}
            isHomePage={isHomePage}
            isMultipleProducer={isMultipleProducer}
            locale={locale}
            producerName={producerName}
            profileImageUrl={userImage}
            retailerLanguages={retailerLanguages}
            retailerName={retailerName}
            shouldHideAnonymousFooter={shouldHideAnonymousFooter}
            shouldHideCatalogLink={shouldHideCatalogLink}
            shouldHideExploreLink={!retailerLocationProducerId}
            shouldHideHeader={shouldHideHeader}
            shouldHideHomeLink={isDisableHomeAddon}
            shouldHideLoggedFooter={shouldHideLoggedFooter}
            shouldHideTasteIdLink={!isUserHasCharacterForCurrentPC}
            shouldShowAnonymousFooterWave={shouldShowAnonymousFooterWave}
            shouldShowDownloadAppCard={shouldShowDownloadAppCard}
            shouldShowHeaderDropDownMenu={false}
            shouldShowTasteIdGuide={shouldShowTasteIdGuide}
            toastMessages={toastMessages}
          >
            {children}
            {isToShowThirdPartyModal && <ThirdPartyConnectModalContainer />}
          </HeaderFooterWidget>
        )}
      </RootElement>
    );
  }

  if (isApplicationKiosk) {
    return isWelcomePage || !isUserKioskAdmin ? (
      <>{children}</>
    ) : (
      <RootElement>
        <HeaderFooterKiosk
          handleDetachButtonClick={() => handleResetKioskUserSession({ showFidelityMessage: true })}
          handleLogoClick={() => navigate(prependBasename(PAGES.vinhood.home))}
          isHomePage={isHomePage}
          isShowDetachFidelityCard={!!fidelityCardId}
          isShowLanguageSwitcher={isHomePage}
          locale={locale}
          retailerLanguages={retailerLanguages}
        >
          {children}
        </HeaderFooterKiosk>
      </RootElement>
    );
  }

  return <>{children}</>;
};

export default HocWrapper;
