import { FC, useLayoutEffect, useState } from 'react';

import { IActionToggleOpenedFeedbackModalProductProps } from '@lib/core/products/slices/productFeedback';
import { TParsedProductInstance } from '@lib/core/products/types';
import { localeWidget } from '@lib/tools/locale/source/web/widget';

import Button from '@components/web/src/atoms/Buttons/Button';
import Confetti from '@components/web/src/atoms/Confetti/Confetti';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import { ProductFeedbackDoneProductSection } from '@components/web/src/organisms/ProductFeedback/ProductFeedbackDoneProductSection/ProductFeedbackDoneProductSection';
import * as S from '@components/web/src/templates/ProductFeedback/ProductFeedbackPageDone/styles';

interface IProps {
  parsedProductInstanceData: TParsedProductInstance;
  locale: string;
  handleNavigate: (args?: IActionToggleOpenedFeedbackModalProductProps) => void;
}

export const ProductFeedbackPageDone: FC<IProps> = ({ parsedProductInstanceData, handleNavigate }) => {
  const {
    productName = '',
    productCategory = '',
    productImage = '',
    productProducerName = '',
  } = parsedProductInstanceData || {};

  const { tastingCardCollectedText, tastingIsCompleteText, collectMoreBtn } = localeWidget.ProductFeedbackStartPage;

  const [showConfetti, setShowConfetti] = useState(false);

  useLayoutEffect(() => {
    setShowConfetti(true);
    const confettiTimeout = setTimeout(() => {
      setShowConfetti(false);
    }, 2000);

    return () => clearTimeout(confettiTimeout);
  }, []);

  return (
    <S.ProductFeedbackDonePageContainer isFullWidth direction="column" gap={0}>
      {showConfetti && <Confetti />}
      <>
        <S.Header align="center" direction="column" gap={16} padding="30px 16px 16px">
          <S.TitleText fontFamily="Fraunces" size="h2" text={tastingCardCollectedText} weight="semibold" />
          <Text localeOptions={{ productCategory }} size="body1" text={tastingIsCompleteText} weight="bold" />
        </S.Header>
        <Flexbox isFullWidth align="center" direction="column" justify="center" margin="6px 0 0">
          <ProductFeedbackDoneProductSection image={productImage} variant="lg" />
          <S.Divider align="center" direction="column" justify="center">
            <S.CheckIcon />
          </S.Divider>
        </Flexbox>
        <S.ProductDetails direction="column" gap={8}>
          <Text
            color={STATIC_COLORS.base.white}
            fontFamily="Fraunces"
            linesLimit={2}
            size="h4"
            text={productName}
            weight="semibold"
          />
          <Text color={STATIC_COLORS.base.white} size="body1" text={productProducerName} weight="bold" />
        </S.ProductDetails>
        <S.ActionButtonWrapper align="center" justify="center">
          <Button
            isFullWidth
            fontSize="subtitle2"
            handleClick={() => handleNavigate()}
            size="md"
            text={collectMoreBtn}
          />
        </S.ActionButtonWrapper>
      </>
    </S.ProductFeedbackDonePageContainer>
  );
};
