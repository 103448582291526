import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useBanners } from '@lib/core/banners/hooks/banners';
import { actionGetBanners } from '@lib/core/banners/slices/banners';
import { TBanner } from '@lib/core/banners/types';
import { useCharacters } from '@lib/core/characters/hooks';
import { isCharacterByIdentifiers } from '@lib/core/characters/utils/filters';
import { useParsedProducts } from '@lib/core/products/hooks/useParsedProducts';
import {
  selectAllFeedbacksByProductId,
  selectIsAnyProductFeedbackCompleted,
  selectIsProductFeedbackAnswerSubmitLoading,
  selectIsProductFeedbackDataOrOptionsLoading,
  selectOpenedFeedbackModalProductInstanceData,
  selectProductFeedbackCurrentQuestion,
  selectProductFeedbackDataLocale,
  selectProductFeedbackModalProductIsOpenedForCurrentRtl,
  selectProductFeedbackModalType,
  selectProductFeedbackQuestions,
  selectProductFeedbackQuestionsData,
  selectProductFeedbackTutorialProduct,
} from '@lib/core/products/selectors/productFeedback';
import {
  IProductFeedbackAnswer,
  ProductFeedbackSteps,
  actionDoneProductFeedbackAnswer,
  actionForwardProductFeedbackStep,
  actionGetExistingProductFeedbacks,
  actionGetProductFeedbackQuestions,
  actionPostProductFeedbackAnswer,
  actionToggleOpenedFeedbackModal,
  actionToggleProductFeedbackTutorial,
} from '@lib/core/products/slices/productFeedback';
import { TParsedProductInstance, TProductInstance } from '@lib/core/products/types';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { selectServiceProductCategory } from '@lib/core/service/selectors';
import { getMultipleUniqueRandomItemsFromArray } from '@lib/core/service/utils';

export type TToggleOpenedFeedbackModalProductProps = {
  productFeedbackModalStep?: ProductFeedbackSteps;
  productInstanceData?: TProductInstance;
};

export const useProductFeedback = () => {
  const dispatch = useDispatch();
  const currentFeedbackProductInstanceData = useSelector(selectOpenedFeedbackModalProductInstanceData);
  const productFeedbackModalOpenedForCurrentRtl = useSelector(selectProductFeedbackModalProductIsOpenedForCurrentRtl);
  const productFeedbackQuestionsData = useSelector(selectProductFeedbackQuestionsData);
  const productFeedbackCurrentQuestion = useSelector(selectProductFeedbackCurrentQuestion);
  const serviceProductCategory = useSelector(selectServiceProductCategory);
  const isProductFeedbackAnswerSubmitLoading = useSelector(selectIsProductFeedbackAnswerSubmitLoading);
  const isProductFeedbackDataOrOptionsLoading = useSelector(selectIsProductFeedbackDataOrOptionsLoading);
  const productFeedbackStep = useSelector(selectProductFeedbackModalType);
  const productFeedbackQuestions = useSelector(selectProductFeedbackQuestions);
  const productFeedbackDataLocale = useSelector(selectProductFeedbackDataLocale);
  const finishedProductFeedbacksByProductId = useSelector(selectAllFeedbacksByProductId);
  const productFeedbackTutorialProduct = useSelector(selectProductFeedbackTutorialProduct);
  const isAnyProductFeedbackCompleted = useSelector(selectIsAnyProductFeedbackCompleted);

  const { parseProduct } = useParsedProducts();
  const { retailerLocationId } = useRetailerLocation();
  const { productFeedbackBannersData } = useBanners();
  const { characters } = useCharacters();

  const parsedProductInstanceData = parseProduct(currentFeedbackProductInstanceData);

  const { productCharacterId, productCategory, productId } = parsedProductInstanceData || {};

  const productCharacterTypeSlug = useMemo(
    () => characters.find(character => isCharacterByIdentifiers(character, [productCharacterId]))?.characterType?.slug,
    [characters, productId],
  );

  const getProductFeedbackQuestions = ({ locale }: { locale: string }) =>
    dispatch(
      actionGetProductFeedbackQuestions({
        characterTypeSlug: productCharacterTypeSlug,
        locale,
        productCategory,
        productId,
      }),
    );
  const getProductFeedbackBanners = () => dispatch(actionGetBanners({ characterTypeSlug: productCharacterTypeSlug }));

  const toggleOpenedFeedbackModal = ({
    productInstanceData,
    productFeedbackModalStep,
  }: TToggleOpenedFeedbackModalProductProps = {}) => {
    dispatch(
      actionToggleOpenedFeedbackModal({
        productFeedbackModalStep,
        productInstanceData,
        retailerLocationId,
        serviceProductCategory,
      }),
    );
  };

  const handleProductFeedbackAnswerDone = ({ questionId }: { questionId: string }) =>
    dispatch(actionDoneProductFeedbackAnswer({ questionId }));

  const submitProductFeedbackAnswer = ({ answer }: { answer: IProductFeedbackAnswer }) =>
    dispatch(
      actionPostProductFeedbackAnswer({
        answer: answer.slug || answer.identifier,
        context: productFeedbackCurrentQuestion.context,
        product: currentFeedbackProductInstanceData.product.identifier,
        question: productFeedbackCurrentQuestion.identifier,
      }),
    );
  const forwardProductFeedbackStep = () => dispatch(actionForwardProductFeedbackStep());

  const toggleProductFeedbackTutorial = ({
    parsedProductInstance,
  }: {
    parsedProductInstance?: TParsedProductInstance;
  }) => dispatch(actionToggleProductFeedbackTutorial({ parsedProductInstance }));

  const getAllSubmittedProductFeedbacks = ({ locale }: { locale?: string }) =>
    dispatch(actionGetExistingProductFeedbacks({ locale }));

  const productFeedbackBanners: TBanner[] = useMemo(
    () =>
      getMultipleUniqueRandomItemsFromArray(
        productFeedbackBannersData,
        productFeedbackQuestionsData?.choices?.questions?.length,
      ),
    [productFeedbackBannersData?.length, productFeedbackQuestionsData?.name],
  );

  return {
    finishedProductFeedbacksByProductId,
    forwardProductFeedbackStep,
    getAllSubmittedProductFeedbacks,
    getProductFeedbackBanners,
    getProductFeedbackQuestions,
    handleProductFeedbackAnswerDone,
    isAnyProductFeedbackCompleted,
    isProductFeedbackAnswerSubmitLoading,
    isProductFeedbackDataOrOptionsLoading,
    parsedProductInstanceData,
    productFeedbackBanners,
    productFeedbackCurrentQuestion,
    productFeedbackDataLocale,
    productFeedbackModalOpenedForCurrentRtl,
    productFeedbackQuestions,
    productFeedbackQuestionsData,
    productFeedbackStep,
    productFeedbackTutorialProduct,
    submitProductFeedbackAnswer,
    toggleOpenedFeedbackModal,
    toggleProductFeedbackTutorial,
  };
};
