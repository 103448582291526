import { FC, useState } from 'react';

import { IComment } from '@lib/core/comments/types';
import { IHandleSubmitCommentProps, TProductCategory } from '@lib/core/products/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { LocaleUtils } from '@lib/tools/locale/utils';

import commentHeader from '@components/web/src/assets/icons/training/commentHeader.png';
import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import { TextArea } from '@components/web/src/foundations/TextArea/TextArea';
import * as S from '@components/web/src/organisms/ProductFeedback/ProductFeedbackCommentCard/styles';

interface IProps {
  commentFromList: IComment;
  isCommentInList: boolean;
  productCategory: TProductCategory;
  handleSubmitComment?: (args: IHandleSubmitCommentProps) => Promise<void>;
  handleSkip: () => void;
  isCommentUpdateLoading?: boolean;
}

export const ProductFeedbackCommentCard: FC<IProps> = ({
  commentFromList,
  isCommentUpdateLoading,
  productCategory,
  handleSubmitComment,
  handleSkip,
}) => {
  const [comment, setComment] = useState(commentFromList?.comment);

  const { placeholderText } = localeCommon.comment;
  const { publishedTerms } = LocaleUtils;
  const textAreaPlaceholderText = publishedTerms[placeholderText?.id];
  const isSaveCommentCtaDisabled = comment === '' || comment === commentFromList?.comment;
  const { skipBtn, continueBtn, commentPageTitle, commentPageDescription, commentPageSubtitle } =
    localeWidget.productFeedback;

  return (
    <S.ProductFeedbackCommentCardContainer
      $productCategory={productCategory}
      align="center"
      direction="column"
      gap={16}
      padding="16px"
    >
      <Flexbox isFullWidth direction="column" gap={16}>
        <Image alt="image" src={commentHeader} />
        <Flexbox direction="column" gap={8}>
          <Text color={STATIC_COLORS.base.black} size="body2" text={commentPageTitle} weight="bold" />
          <Text
            color={STATIC_COLORS.base.black}
            fontFamily="Fraunces"
            size="h6"
            text={commentPageDescription}
            weight="bold"
          />
          <Text color={STATIC_COLORS.base.black} size="body2" text={commentPageSubtitle} />
        </Flexbox>
      </Flexbox>
      <S.CommentWrapper isFullWidth align="center" direction="column" gap={16} justify="center">
        <TextArea
          minHeight="128px"
          placeholder={textAreaPlaceholderText}
          value={comment}
          onChange={event => {
            setComment(event.target.value);
          }}
        />
        <Flexbox isFullWidth direction="column" gap={16}>
          <Button
            disabled={isSaveCommentCtaDisabled || isCommentUpdateLoading}
            fontSize="subtitle2"
            size="md"
            text={continueBtn}
            handleClick={() => {
              handleSubmitComment({ comment }).then(() => handleSkip());
            }}
          />
          <Button
            fontSize="subtitle2"
            handleClick={handleSkip}
            size="md"
            text={skipBtn}
            textDecoration="underline"
            textWeight="bold"
            variant="transparent"
          />
        </Flexbox>
      </S.CommentWrapper>
    </S.ProductFeedbackCommentCardContainer>
  );
};
