import styled from 'styled-components';

import BackButton from '@components/web/src/atoms/Buttons/BackButton/BackButton';
import { STATIC_COLORS, STATIC_GRADIENTS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

export const ProductFeedbackDonePageContainer = styled(Flexbox)<{ $isTimer?: boolean }>`
  position: relative;
  background: ${STATIC_GRADIENTS.primaryGradient};
  align-items: center;
  padding: ${({ $isTimer }) => ($isTimer ? '0px 16px 128px 16px' : '0 0 66px')};
  height: 100%;
`;

export const StyledBackButton = styled(BackButton)`
  background-color: ${STATIC_COLORS.base.white};
  box-sizing: border-box;
  width: 100%;
  padding: 14px;
`;

export const TitleText = styled(Text)`
  color: ${STATIC_COLORS.gray[25]};
  -webkit-text-stroke-width: 6px;
  -webkit-text-stroke-color: #338e9a;
  paint-order: stroke fill;
  text-shadow:
    0 9px 9px #3d80c1,
    0 6px 13px #24a360;
`;

export const Header = styled(Flexbox)`
  color: ${STATIC_COLORS.base.white};
  width: 100%;
  text-align: center;
  padding: 32px 15px 16px;
  gap: 16px;
  align-items: center;
  flex-direction: column;
`;

export const ProductDetails = styled(Flexbox)`
  text-align: center;
  padding: 47.96px 16px 8px;
  flex: 1;
  & h3,
  & p {
    margin: 0;
  }
`;

export const CountdownContainer = styled(Flexbox)`
  padding: 188px 0;
  color: ${STATIC_COLORS.base.white};
  text-align: center;
  height: 100%;
`;

export const NumberContainer = styled.div`
  width: 200px;
  height: 200px;

  span {
    font-size: 120px;
    line-height: 200px;
  }
`;
