import { Dispatch, FC, SetStateAction, useState } from 'react';

import { IComment } from '@lib/core/comments/types';
import { IHandleSubmitCommentProps } from '@lib/core/products/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { LocaleUtils } from '@lib/tools/locale/utils';

import noteIconFill from '@components/web/src/assets/icons/comment/NoteIconFill.svg';
import Button from '@components/web/src/atoms/Buttons/Button';
import CloseButton from '@components/web/src/atoms/Buttons/CloseButton/CloseButton';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import { TextArea } from '@components/web/src/foundations/TextArea/TextArea';
import * as S from '@components/web/src/templates/Modals/CommentModal/styles';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';

interface Props {
  onClose: () => void;
  isCommentsDataLoading: boolean;
  isCommentUpdateLoading: boolean;
  setIsNewCommentsDataFetching: Dispatch<SetStateAction<boolean>>;
  handleSubmitComment: (args: IHandleSubmitCommentProps) => Promise<void>;
  commentFromList: IComment;
  isCommentModalOpen: boolean;
}

const CommentModal: FC<Props> = ({
  onClose,
  isCommentUpdateLoading,
  handleSubmitComment,
  commentFromList,
  isCommentModalOpen,
}) => {
  const [comment, setComment] = useState(commentFromList?.comment);

  const { modalTitle, save, cancel, placeholderText } = localeCommon.comment;
  const { publishedTerms } = LocaleUtils;
  const textAreaPlaceholderText = publishedTerms[placeholderText?.id];
  const isSaveCommentCtaDisabled = comment === '' || comment === commentFromList?.comment || isCommentUpdateLoading;

  const handleCancel = () => {
    onClose();
  };

  return (
    <ModalWrapper maxContentHeight hideModal={onClose} isOpen={isCommentModalOpen}>
      <S.CommentModalContainer isFullWidth direction="column" gap={16} padding="16px">
        <S.CloseIcon>
          <CloseButton handleClick={onClose} variant="dark" />
        </S.CloseIcon>
        <S.TitleWrapper>
          <S.ImageWrapper>
            <Image alt="Comment icon" height="20px" src={noteIconFill} width="25px" />
          </S.ImageWrapper>
          <Text color={STATIC_COLORS.base.black} fontFamily="Fraunces" size="h6" text={modalTitle} weight="medium" />
        </S.TitleWrapper>
        <S.CommentForm>
          <TextArea
            placeholder={textAreaPlaceholderText}
            value={comment}
            onChange={event => {
              setComment(event.target.value);
            }}
          />
          <Flexbox isFullWidth direction="column" gap={8}>
            <Button
              disabled={isSaveCommentCtaDisabled}
              fontSize="subtitle2"
              size="md"
              text={save}
              type="submit"
              onClick={() => {
                handleSubmitComment({ comment }).then(() => onClose());
              }}
            />
            <Button fontSize="subtitle2" size="md" text={cancel} variant="link" onClick={handleCancel} />
          </Flexbox>
        </S.CommentForm>
      </S.CommentModalContainer>
    </ModalWrapper>
  );
};
export default CommentModal;
