import styled from 'styled-components';

import { ReactComponent as CheckIconSvg } from '@components/web/src/assets/icons/training/icon-check.svg';
import BackButton from '@components/web/src/atoms/Buttons/BackButton/BackButton';
import { STATIC_COLORS, STATIC_GRADIENTS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

export const ProductFeedbackDonePageContainer = styled(Flexbox)`
  flex: 1 1 0%;
  position: relative;
  background: ${STATIC_GRADIENTS.primaryGradient};
  overflow-y: auto;
  height: 100%;
`;

export const StyledBackButton = styled(BackButton)`
  background-color: ${STATIC_COLORS.base.white};
  box-sizing: border-box;
  padding: 16px;
`;

export const TitleText = styled(Text)`
  color: ${STATIC_COLORS.gray[25]};
  -webkit-text-stroke-width: 6px;
  -webkit-text-stroke-color: #338e9a;
  paint-order: stroke fill;
  text-shadow:
    0px 12px 16px rgba(16, 24, 40, 0.1),
    0px 4px 6px rgba(16, 24, 40, 0.05);
`;

export const Header = styled(Flexbox)`
  color: ${STATIC_COLORS.base.white};
  width: 100%;
  text-align: center;
`;

export const Divider = styled(Flexbox)`
  position: relative;
  width: 286px;
  border-top: 1px solid ${STATIC_COLORS.base.white};
`;

export const CheckIcon = styled(CheckIconSvg)`
  position: absolute;
  width: 45px;
  height: 44.938px;
  flex-shrink: 0;
`;

export const ProductDetails = styled(Flexbox)`
  text-align: center;
  padding: 31.96px 12px 0;
  flex: 1;
  & h3,
  & p {
    margin: 0;
  }
`;

export const ActionButtonWrapper = styled(Flexbox)`
  width: 100%;
  padding: 8px 16px;
  background: ${STATIC_COLORS.base.white};
  margin-top: auto;
`;
