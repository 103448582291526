import { useEffect, useState } from 'react';

import { useComments } from '@lib/core/comments/hooks/useComments';
import { actionCreateComment, actionGetComments, actionUpdateComment } from '@lib/core/comments/slices/comments';
import { ICommentHandleSubmit } from '@lib/core/comments/types';
import { useRetailerDesignSet } from '@lib/tools/views/hooks';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';

export const useCommentsHandler = () => {
  const dispatch = useTypedDispatch();
  const { isDesignSetVinhoodApp } = useRetailerDesignSet();
  const { isCommentCreating, isCommentUpdating, commentsList, isCommentsLoading } = useComments();

  const isCommentsDataLoading = isCommentsLoading || isCommentUpdating || isCommentCreating;

  const isCommentUpdateLoading = isCommentUpdating || isCommentCreating;

  const [isNewCommentsDataFetching, setIsNewCommentsDataFetching] = useState(false);
  const handleComment = async ({
    isCommentInList,
    commentFromList,
    productId,
    comment,
    isProducerFeedback,
  }: ICommentHandleSubmit) => {
    try {
      if (isCommentInList && !isProducerFeedback) {
        await dispatch(actionUpdateComment({ identifier: commentFromList?.identifier, updateComment: comment }));
      } else {
        await dispatch(actionCreateComment({ isProducerFeedback, newComment: comment, productIdentifier: productId }));
      }
      setIsNewCommentsDataFetching(true);
    } catch (error) {
      console.error('Comment update failed:', error);
      throw error;
    }
  };

  useEffect(() => {
    if (isNewCommentsDataFetching && (!isCommentUpdating || !isCommentCreating)) {
      dispatch(actionGetComments());
    }
  }, [isCommentCreating, isNewCommentsDataFetching, isCommentUpdating]);
  return {
    commentsList,
    handleComment,
    isCommentUpdateLoading,
    isCommentsDataLoading,
    isDesignSetVinhoodApp,
    isNewCommentsDataFetching,
    setIsNewCommentsDataFetching,
  };
};
