import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { TBanner } from '@lib/core/banners/types';
import {
  IProductFeedbackAnswer,
  IProductFeedbackAnswerLoadedOrPending,
  IProductFeedbackQuestion,
  QuestionContext,
  actionGetQuestionAnswer,
} from '@lib/core/products/slices/productFeedback';
import { TProductCategory } from '@lib/core/products/types';

import aromaHeader from '@components/web/src/assets/icons/training/aromaHeader.png';
import colorHeader from '@components/web/src/assets/icons/training/colorHeader.png';
import tasteHeader from '@components/web/src/assets/icons/training/tasteHeader.png';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import { TastingTip } from '@components/web/src/organisms/Cards/TastingTip/TastingTip';
import { ProductFeedbackAnswerCard } from '@components/web/src/organisms/ProductFeedback/ProductFeedbackAnswerCard/ProductFeedbackAnswerCard';
import * as S from '@components/web/src/organisms/ProductFeedback/ProductFeedbackQuestionsCard/styles';

interface IProps {
  answers: IProductFeedbackAnswerLoadedOrPending[];
  productFeedbackCurrentQuestion: IProductFeedbackQuestion;
  productCategory: TProductCategory;
  isProductFeedbackAnswerSubmitLoading?: boolean;
  handleSubmitAnswer: ({ answer }: { answer: IProductFeedbackAnswer }) => void;
  banner: TBanner;
}

export const ProductFeedbackQuestionsCard: FC<IProps> = ({
  productCategory,
  answers,
  isProductFeedbackAnswerSubmitLoading,
  handleSubmitAnswer,
  banner,
  productFeedbackCurrentQuestion,
}) => {
  const dispatch = useDispatch();
  const [selectedAnswerIdOrSlug, setSelectedAnswerIdOrSlug] = useState('');
  const { image, subtitle, title, description } = productFeedbackCurrentQuestion;

  const renderedImage = useMemo(() => {
    switch (productFeedbackCurrentQuestion.context) {
      case QuestionContext.color:
        return colorHeader;
      case QuestionContext.aroma:
        return aromaHeader;
      case QuestionContext.taste:
        return tasteHeader;
      default:
        return '';
    }
  }, [image]);

  useEffect(() => {
    // For very corner case if page was reloaded while answers requests thunk from a thunk are pending
    answers.forEach(answer => {
      if ('urlToBeLoaded' in answer)
        dispatch(
          actionGetQuestionAnswer({ questionId: productFeedbackCurrentQuestion.identifier, url: answer.urlToBeLoaded }),
        );
    });
  }, []);

  return (
    <S.ProductFeedbackQuestionsCardContainer
      $productCategory={productCategory}
      align="center"
      direction="column"
      gap={16}
      padding="16px"
    >
      <Flexbox direction="column" gap={16}>
        <Image alt="image" height="124px" src={renderedImage} />
        <Flexbox align="center" direction="column" gap={8}>
          <Text color={STATIC_COLORS.base.black} size="body2" text={subtitle} weight="bold" />
          <Text color={STATIC_COLORS.base.black} fontFamily="Fraunces" size="h6" text={title} weight="bold" />
          <Text color={STATIC_COLORS.base.black} size="body2" text={description} />
        </Flexbox>
      </Flexbox>
      <>
        {answers?.map(answer => {
          if ('answer' in answer)
            return (
              <ProductFeedbackAnswerCard
                answer={answer.answer}
                handleSubmitAnswer={() => handleSubmitAnswer({ answer: answer.answer })}
                isProductFeedbackAnswerSubmitLoading={isProductFeedbackAnswerSubmitLoading}
                setSelectedAnswerIdOrSlug={setSelectedAnswerIdOrSlug}
                isOpenedAnswer={
                  answer.answer.identifier === selectedAnswerIdOrSlug || answer.answer.slug === selectedAnswerIdOrSlug
                }
              />
            );
          return null;
        })}
      </>
      <Flexbox direction="column" gap={16}>
        <TastingTip text={banner?.text} />
      </Flexbox>
    </S.ProductFeedbackQuestionsCardContainer>
  );
};
